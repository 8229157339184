import React, { Component } from 'react';
import { compose } from 'recompose'
import { db } from '../firebase'
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom'
import List360 from '../components/list360'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
import SiteFooterBrand from '../components/site-footer-brand'
import Modal from '../components/global/modal'
import Autocomplete from 'react-autocomplete'
import { lightBoxStyles } from '../constants/staticVariables.js'
const authCondition = (authUser) => !!authUser
//

class Contact extends Component {
  render() {
    return (
      <div className="container container--slim">
        <section className="welcome">
          <div className="container">
            <div className="welcome__row">
            <h1><span>Contact</span> Us</h1>
            <p>Please use the contact details below to drop us a line if you have any questions.</p>
            </div>
          </div>
        </section>
        <div className="page-wrapper">
          <div className="flex justify-between">
            <div className="bg--white shadow pa4 w-60">
              <h3>Problems with the platform?</h3><p>E: pete@boomerang-hr.com</p>
              <h3>Help with reports or question sets?</h3><p>E: help@boomerang-hr.com</p>
            </div>
            <div className="bg--white shadow pa4 w-30">
              <div className="tc">
                <h3>Downloads</h3>
                <h4>Setting up a 360<br/><br/><a className="btn" href="https://firebasestorage.googleapis.com/v0/b/boomerang-990c9.appspot.com/o/pdfs%2FBoomerang-Setting_up_a_360.pdf?alt=media&token=e3255273-253c-4adf-aca0-17e1b2bcf5c1">Download PDF Guide</a></h4>
                <h4>Editing Relationship Titles<br/><br/><a className="btn" href="https://firebasestorage.googleapis.com/v0/b/boomerang-990c9.appspot.com/o/pdfs%2FBoomerang-Edit_Relationship%20Titles.pdf?alt=media&token=aaf71d56-a09b-485b-aae9-a4c70087406e">Download PDF Guide</a></h4>
                <h4>Editing email copy<br/><br/><a className="btn" href="https://firebasestorage.googleapis.com/v0/b/boomerang-990c9.appspot.com/o/pdfs%2FBoomerang-Edit_Email.pdf?alt=media&token=fb8d358f-d03b-4354-a4ab-46a8b92a926d">Download PDF Guide</a></h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Contact)
