import React, { Component } from 'react';
import Dropdown from './global/dropdown';

class ClientSelection extends Component {
  render() {
    const {accountData, clientItemListFilter, fireClientFilter, sortList, updateSortID, containerClasses} = this.props;
    return (
      <div className={`flex items-center ${containerClasses}`}>
        <div className='flex items-center'>
          <p className='ma0'>
            <strong>Sort by:</strong>
          </p>
          <Dropdown
            title='Alphabetical A-Z'
            list={sortList} 
            setTitleOnSelect={true} 
            resetThenSet={updateSortID} 
          />
        </div>

        {!accountData.clientname && (
          <div className='flex items-center'>
            <p className='ma0'>
              <strong>Filter by:</strong>
            </p>
            <Dropdown title='All clients' list={clientItemListFilter} setTitleOnSelect={true} resetThenSet={fireClientFilter} />
          </div>
        )}
      </div>
    );
  }
}

export default ClientSelection;
